import * as i0 from '@angular/core';
import { inject, Pipe } from '@angular/core';
import { TUI_MONTHS } from '@taiga-ui/core/tokens';
import { map } from 'rxjs';
class TuiMonthPipe {
  constructor() {
    this.months$ = inject(TUI_MONTHS);
  }
  transform({
    month
  }) {
    return this.months$.pipe(map(months => months[month] || months[0]));
  }
  static {
    this.ɵfac = function TuiMonthPipe_Factory(t) {
      return new (t || TuiMonthPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "tuiMonth",
      type: TuiMonthPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMonthPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'tuiMonth'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiMonthPipe };
