import { inject } from '@angular/core';
import { tuiCreateTokenFromFactory, tuiCreateToken } from '@taiga-ui/cdk/utils/miscellaneous';
import { TUI_ENGLISH_LANGUAGE } from '@taiga-ui/i18n/languages/english';
import { of } from 'rxjs';

/**
 * Default Language for Taiga UI libraries i18n
 */
const TUI_DEFAULT_LANGUAGE = tuiCreateTokenFromFactory(() => TUI_ENGLISH_LANGUAGE);
/**
 * Language for Taiga UI libraries i18n
 */
const TUI_LANGUAGE = tuiCreateTokenFromFactory(() => of(inject(TUI_DEFAULT_LANGUAGE)));

/**
 * Webpack chunk loader for Taiga UI libraries i18n
 * @note: cannot be transferred to a shared file
 * ReferenceError: Cannot access 'TUI_LANGUAGE_LOADER' before initialization
 */
const TUI_LANGUAGE_LOADER = tuiCreateToken();

/**
 * Default key for search value in storage
 */
const TUI_LANGUAGE_STORAGE_KEY = tuiCreateToken('tuiLanguage');

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_DEFAULT_LANGUAGE, TUI_LANGUAGE, TUI_LANGUAGE_LOADER, TUI_LANGUAGE_STORAGE_KEY };

