import * as i0 from '@angular/core';
import { inject, Injectable, Directive, Output, Input } from '@angular/core';
import { TuiActiveZone } from '@taiga-ui/cdk/directives/active-zone';
import { tuiZonefreeScheduler, tuiZoneOptimized, tuiIfMap } from '@taiga-ui/cdk/observables';
import { Observable, throttleTime, map, startWith, distinctUntilChanged, Subject } from 'rxjs';
import { WA_ANIMATION_FRAME } from '@ng-web-apis/common';
import { tuiInjectElement, tuiGetElementObscures } from '@taiga-ui/cdk/utils/dom';

/**
 * Service that monitors element visibility by polling and returning
 * either null or an array of elements that overlap given element edges
 */
class TuiObscuredService extends Observable {
  constructor() {
    super(subscriber => this.obscured$.subscribe(subscriber));
    this.el = tuiInjectElement();
    this.obscured$ = inject(WA_ANIMATION_FRAME).pipe(throttleTime(100, tuiZonefreeScheduler()), map(() => tuiGetElementObscures(this.el)), startWith(null), distinctUntilChanged(), tuiZoneOptimized());
  }
  static {
    this.ɵfac = function TuiObscuredService_Factory(t) {
      return new (t || TuiObscuredService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TuiObscuredService,
      factory: TuiObscuredService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiObscuredService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();

/**
 * Directive that monitors element visibility
 */
class TuiObscured {
  constructor() {
    this.activeZone = inject(TuiActiveZone, {
      optional: true
    });
    this.enabled$ = new Subject();
    this.obscured$ = inject(TuiObscuredService, {
      self: true
    }).pipe(map(by => !!by?.every(el => !this.activeZone?.contains(el))));
    this.tuiObscured = this.enabled$.pipe(tuiIfMap(() => this.obscured$));
  }
  set tuiObscuredEnabled(enabled) {
    this.enabled$.next(enabled);
  }
  static {
    this.ɵfac = function TuiObscured_Factory(t) {
      return new (t || TuiObscured)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiObscured,
      selectors: [["", "tuiObscured", ""]],
      inputs: {
        tuiObscuredEnabled: "tuiObscuredEnabled"
      },
      outputs: {
        tuiObscured: "tuiObscured"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([TuiObscuredService])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiObscured, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiObscured]',
      providers: [TuiObscuredService]
    }]
  }], null, {
    tuiObscured: [{
      type: Output
    }],
    tuiObscuredEnabled: [{
      type: Input
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiObscured, TuiObscuredService };
