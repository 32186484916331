const TUI_ENGLISH_LANGUAGE_ADDON_COMMERCE = {
    cardNumber: ['Number', 'Card number'],
    cardExpiry: ['Expires', 'Valid through'],
};

const TUI_ENGLISH_LANGUAGE_ADDON_EDITOR = {
    colorSelectorModeNames: ['Solid color', 'Gradient'],
    toolbarTools: {
        undo: 'Undo',
        redo: 'Redo',
        font: 'Font',
        fontStyle: 'Font style',
        fontSize: 'Font size',
        bold: 'Bold',
        italic: 'Italic',
        underline: 'Underline',
        strikeThrough: 'Strike through',
        justify: 'Justify',
        justifyLeft: 'Justify left',
        justifyCenter: 'Justify center',
        justifyRight: 'Justify right',
        justifyFull: 'Justify full',
        list: 'List',
        indent: 'Indent',
        outdent: 'Outdent',
        unorderedList: 'Unordered list',
        orderedList: 'Ordered list',
        quote: 'Quote',
        foreColor: 'Color',
        backColor: 'Background color',
        hiliteColor: 'Highlight color',
        clear: 'Clear',
        link: 'Link',
        attach: 'Attach file',
        tex: 'Insert TeX',
        code: 'Code',
        image: 'Insert image',
        insertHorizontalRule: 'Insert horizontal rule',
        superscript: 'Superscript',
        subscript: 'Subscript',
        insertTable: 'Insert table',
        insertGroup: 'Insert group',
        hiliteGroup: 'Hilite group',
        removeGroup: 'Remove group',
        insertAnchor: 'Insert anchor',
        mergeCells: 'Merge cells',
        splitCells: 'Split cells',
        rowsColumnsManaging: 'Managing rows and columns',
        cellColor: 'Cell color',
        setDetails: 'Details',
        removeDetails: 'Remove details',
    },
    editorEditLink: {
        urlExample: 'example.com',
        anchorExample: 'anchor',
    },
    editorTableCommands: [
        ['Insert column before', 'Insert column after'],
        ['Insert row before', 'Insert row after'],
        ['Delete column', 'Delete row'],
    ],
    editorCodeOptions: ['Code in the text', 'Code in block'],
    editorFontOptions: {
        small: 'Small',
        large: 'Large',
        normal: 'Normal',
        title: 'Title',
        subtitle: 'Subtitle',
    },
};

const TUI_ENGLISH_LANGUAGE_ADDON_TABLE = {
    showHideText: 'Show/Hide',
    paginationTexts: {
        pages: 'Pages',
        linesPerPage: 'Lines per page',
        of: 'of',
    },
};

const TUI_ENGLISH_LANGUAGE_COUNTRIES = {
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua & Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AO: 'Angola',
    AR: 'Argentina',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AZ: 'Azerbaijan',
    BA: 'Bosnia & Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgium',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'St. Barthélemy',
    BM: 'Bermuda',
    BN: 'Brunei',
    BO: 'Bolivia',
    BQ: 'Caribbean Netherlands',
    BR: 'Brazil',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canada',
    CD: 'Congo - Kinshasa',
    CF: 'Central African Republic',
    CG: 'Congo - Brazzaville',
    CH: 'Switzerland',
    CI: 'Côte d’Ivoire',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cape Verde',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czechia',
    DE: 'Germany',
    DJ: 'Djibouti',
    DK: 'Denmark',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egypt',
    ER: 'Eritrea',
    ES: 'Spain',
    ET: 'Ethiopia',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Islands',
    FM: 'Federated States of Micronesia',
    FR: 'France',
    GA: 'Gabon',
    GB: 'United Kingdom',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'French Guiana',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Greenland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GT: 'Guatemala',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IN: 'India',
    IQ: 'Iraq',
    IR: 'Iran',
    IS: 'Iceland',
    IT: 'Italy',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KM: 'Comoros',
    KN: 'St. Kitts & Nevis',
    KP: 'North Korea',
    KR: 'South Korea',
    KW: 'Kuwait',
    KY: 'Cayman Islands',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Lebanon',
    LC: 'St. Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    LY: 'Libya',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova',
    ME: 'Montenegro',
    MF: 'St. Martin',
    MG: 'Madagascar',
    MK: 'North Macedonia',
    ML: 'Mali',
    MM: 'Myanmar (Burma)',
    MN: 'Mongolia',
    MO: 'Macao',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NZ: 'New Zealand',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'French Polynesia',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Poland',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russia',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SB: 'Solomon Islands',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Sweden',
    SG: 'Singapore',
    SH: 'St. Helena',
    SI: 'Slovenia',
    SK: 'Slovakia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    ST: 'São Tomé & Príncipe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Syria',
    SZ: 'Eswatini',
    TC: 'Turks & Caicos Islands',
    TD: 'Chad',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TL: 'Timor-Leste',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Türkiye',
    TT: 'Trinidad & Tobago',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    UA: 'Ukraine',
    UG: 'Uganda',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VC: 'St. Vincent & Grenadines',
    VE: 'Venezuela',
    VG: 'British Virgin Islands',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    AC: 'Ascension Island',
    AS: 'American Samoa',
    AX: 'Åland Islands',
    CC: 'Cocos (Keeling) Islands',
    CK: 'Cook Islands',
    CX: 'Christmas Island',
    EH: 'Western Sahara',
    FO: 'Faroe Islands',
    GG: 'Guernsey',
    GU: 'Guam',
    IM: 'Isle of Man',
    JE: 'Jersey',
    IO: 'British Indian Ocean Territory',
    KI: 'Kiribati',
    MH: 'Marshall Islands',
    MP: 'Northern Mariana Islands',
    NF: 'Norfolk Island',
    NR: 'Nauru',
    NU: 'Niue',
    PM: 'Saint Pierre and Miquelon',
    PR: 'Puerto Rico',
    PS: 'Palestine',
    SJ: 'Svalbard and Jan Mayen',
    SS: 'South Sudan',
    TA: 'Tristan da Cunha',
    TK: 'Tokelau',
    TV: 'Tuvalu',
    VA: 'Holy See',
    VI: 'Virgin Islands',
    WF: 'Wallis and Futuna',
};

const TUI_ENGLISH_LANGUAGE_CORE = {
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    close: 'Close',
    clear: 'Clear',
    nothingFoundMessage: 'Nothing found',
    defaultErrorMessage: 'Value is invalid',
    spinTexts: ['Previous', 'Next'],
    shortWeekDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    countries: TUI_ENGLISH_LANGUAGE_COUNTRIES,
};

const TUI_ENGLISH_LANGUAGE_KIT = {
    cancel: 'Cancel',
    done: 'Done',
    more: 'More',
    otherDate: 'Other date...',
    showAll: 'Show all',
    hide: 'Hide',
    mobileCalendarTexts: ['Choose day', 'Choose range', 'Choose days'],
    range: ['from', 'to'],
    countTexts: ['Plus', 'Minus'],
    time: {
        'HH:MM': 'HH:MM',
        'HH:MM AA': 'HH:MM AA',
        'HH:MM:SS': 'HH:MM:SS',
        'HH:MM:SS AA': 'HH:MM:SS AA',
        'HH:MM:SS.MSS': 'HH:MM:SS.MSS',
        'HH:MM:SS.MSS AA': 'HH:MM:SS.MSS AA',
    },
    dateTexts: {
        DMY: 'dd.mm.yyyy',
        MDY: 'mm.dd.yyyy',
        YMD: 'yyyy.mm.dd',
    },
    digitalInformationUnits: ['B', 'KiB', 'MiB'],
    passwordTexts: ['Show password', 'Hide password'],
    copyTexts: ['Copy', 'Copied'],
    shortCalendarMonths: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ],
    pagination: ['Previous page', 'Next page'],
    fileTexts: {
        loadingError: 'Upload failed',
        preview: 'Preview',
        remove: 'Remove',
    },
    inputFileTexts: {
        defaultLabelSingle: 'or drop\u00A0it\u00A0here',
        defaultLabelMultiple: 'or drop\u00A0them\u00A0here',
        defaultLinkSingle: 'Choose a file',
        defaultLinkMultiple: 'Choose files',
        maxSizeRejectionReason: 'File is larger than',
        formatRejectionReason: 'Wrong file type',
        drop: 'Drop file here',
        dropMultiple: 'Drop files here',
    },
    multiSelectTexts: {
        all: 'Select all',
        none: 'Select none',
    },
    confirm: {
        yes: 'Yes',
        no: 'No',
    },
    previewTexts: {
        rotate: 'Rotate',
    },
    zoomTexts: {
        zoomOut: 'Zoom out',
        zoomIn: 'Zoom in',
        reset: 'Reset',
    },
    phoneSearch: 'Type country or code',
};

const TUI_ENGLISH_LANGUAGE = {
    name: 'english',
    ...TUI_ENGLISH_LANGUAGE_CORE,
    ...TUI_ENGLISH_LANGUAGE_KIT,
    ...TUI_ENGLISH_LANGUAGE_ADDON_TABLE,
    ...TUI_ENGLISH_LANGUAGE_ADDON_COMMERCE,
    ...TUI_ENGLISH_LANGUAGE_ADDON_EDITOR,
};

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_ENGLISH_LANGUAGE, TUI_ENGLISH_LANGUAGE_ADDON_COMMERCE, TUI_ENGLISH_LANGUAGE_ADDON_EDITOR, TUI_ENGLISH_LANGUAGE_ADDON_TABLE, TUI_ENGLISH_LANGUAGE_CORE, TUI_ENGLISH_LANGUAGE_COUNTRIES, TUI_ENGLISH_LANGUAGE_KIT };

