import * as i0 from '@angular/core';
import { inject, Injectable } from '@angular/core';
import { TUI_LANGUAGE, TUI_LANGUAGE_LOADER, TUI_LANGUAGE_STORAGE_KEY, TUI_DEFAULT_LANGUAGE } from '@taiga-ui/i18n/tokens';
import { map, from, of, switchAll, BehaviorSubject } from 'rxjs';
import { WA_LOCAL_STORAGE } from '@ng-web-apis/common';
function tuiExtractI18n(key) {
  return () => inject(TUI_LANGUAGE).pipe(map(lang => lang[key]));
}

// CommonJS `module.exports` is wrapped as `default` in ESModule.
async function normalizeCommonJSImport(importPromise) {
  return importPromise.then(m => m.default || m);
}
function tuiLoadLanguage(language, loader) {
  return from(normalizeCommonJSImport(loader(language))).pipe(map(module => module?.[`TUI_${language.toUpperCase()}_LANGUAGE`]));
}
function tuiAsyncLoadLanguage(language, loader, fallback) {
  return language && loader ? tuiLoadLanguage(language, loader) : of(fallback);
}
function tuiLanguageSwitcher(loader) {
  return [{
    provide: TUI_LANGUAGE_LOADER,
    useFactory: () => loader
  }, {
    provide: TUI_LANGUAGE,
    useFactory: () => inject(TuiLanguageSwitcherService).pipe(switchAll())
  }];
}
class TuiLanguageSwitcherService extends BehaviorSubject {
  constructor() {
    super(tuiAsyncLoadLanguage(inject(WA_LOCAL_STORAGE).getItem(inject(TUI_LANGUAGE_STORAGE_KEY)), inject(TUI_LANGUAGE_LOADER, {
      optional: true
    }), inject(TUI_DEFAULT_LANGUAGE)));
    this.fallback = inject(TUI_DEFAULT_LANGUAGE);
    this.key = inject(TUI_LANGUAGE_STORAGE_KEY);
    this.storage = inject(WA_LOCAL_STORAGE);
    this.loader = inject(TUI_LANGUAGE_LOADER, {
      optional: true
    });
  }
  get language() {
    return this.storage.getItem(this.key) || this.fallback.name;
  }
  setLanguage(language) {
    this.storage.setItem(this.key, language);
    this.next(tuiAsyncLoadLanguage(language, this.loader, this.fallback));
  }
  clear() {
    this.storage.removeItem(this.key);
    this.next(of(this.fallback));
  }
  static {
    this.ɵfac = function TuiLanguageSwitcherService_Factory(t) {
      return new (t || TuiLanguageSwitcherService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TuiLanguageSwitcherService,
      factory: TuiLanguageSwitcherService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiLanguageSwitcherService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiLanguageSwitcherService, tuiAsyncLoadLanguage, tuiExtractI18n, tuiLanguageSwitcher, tuiLoadLanguage };
