import { tuiCreateToken, tuiProvide, tuiCreateTokenFromFactory } from '@taiga-ui/cdk/utils/miscellaneous';
import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import { from, EMPTY, shareReplay, map, of } from 'rxjs';
import { WA_NAVIGATOR, WA_WINDOW } from '@ng-web-apis/common';
import { isApple } from '@ng-web-apis/platform';
import { TuiMonthPipe } from '@taiga-ui/core/pipes/month';

/**
 * @deprecated: drop in v5.0
 * Use new version of {@link https://taiga-ui.dev/components/input-phone-international InputPhoneInternational}.
 * The new version is based on Google Open Source solution {@link https://github.com/google/libphonenumber libphonenumber}.
 * This token (and all other hard-coded values) and related utils are no more required.
 */
const TUI_COUNTRIES_MASKS = tuiCreateToken({
    AD: '+376###-###',
    AE: '+971-##-###-####',
    AF: '+93##-###-####',
    AG: '+1(268) ###-####',
    AI: '+1(264) ###-####',
    AL: '+355(###) ###-###',
    AM: '+374##-###-###',
    AO: '+244(###) ###-###',
    AR: '+54(###) ####-####',
    AT: '+43(###) ###-####',
    AU: '+61#-####-####',
    AW: '+297###-####',
    AZ: '+994##-###-##-##',
    BA: '+387 ###-####-##',
    BB: '+1(246) ###-####',
    BD: '+880##-###-###',
    BE: '+32(###) ###-###',
    BF: '+226##-##-####',
    BG: '+359(###) ###-###',
    BH: '+973####-####',
    BI: '+257##-##-####',
    BJ: '+229##-##-####',
    BL: '+590 ## ## ## ## ##',
    BM: '+1(441) ###-####',
    BN: '+673###-####',
    BO: '+591#-###-####',
    BQ: '+599-###-####',
    BR: '+55(##) ####-####',
    BS: '+1(242) ###-####',
    BT: '+975#-###-###',
    BW: '+267##-###-###',
    BY: '+375(##) ###-##-##',
    BZ: '+501###-####',
    CA: '+1(###) ###-####',
    CD: '+243(###) ###-###',
    CF: '+236-##-##-####',
    CG: '+242##-###-####',
    CH: '+41##-###-####',
    CI: '+225##-##-###-###',
    CL: '+56#-####-####',
    CM: '+237#####-####',
    CN: '+86(###) ####-####',
    CO: '+57(###) ###-####',
    CR: '+506####-####',
    CU: '+53#-###-####',
    CV: '+238(###) ##-##',
    CW: '+5999-###-####',
    CY: '+357##-###-###',
    CZ: '+420(###) ###-###',
    DE: '+49(###) ###-###-##',
    DJ: '+253##-##-##-##',
    DK: '+45##-##-##-##',
    DM: '+1(767) ###-####',
    DO: '+1(###) ###-####',
    DZ: '+213##-###-####',
    EC: '+593##-###-####',
    EE: '+372####-####',
    EG: '+20(###) ###-####',
    ER: '+291#-###-###',
    ES: '+34(###) ###-###',
    ET: '+251##-###-####',
    FI: '+358(###) ###-##-##',
    FJ: '+679##-#####',
    FK: '+500#####',
    FM: '+691###-####',
    FR: '+33 ## ## ## ## ##',
    GA: '+241##-##-##-##',
    GB: '+44##-####-####',
    GD: '+1(473) ###-####',
    GE: '+995(###) ###-###',
    GF: '+594 ## ## ## ## ##',
    GH: '+233(###) ###-###',
    GI: '+350###-#####',
    GL: '+299##-##-##',
    GM: '+220(###) ##-##',
    GN: '+224##-###-###',
    GP: '+590 ## ## ## ## ##',
    GQ: '+240##-###-####',
    GR: '+30(###) ###-####',
    GT: '+502#-###-####',
    GW: '+245#-######',
    GY: '+592###-####',
    HK: '+852####-####',
    HN: '+504####-####',
    HR: '+385##-###-####',
    HT: '+509##-##-####',
    HU: '+36(###) ###-###',
    ID: '+62(###) ###-##-###',
    IE: '+353(###) ###-###',
    IL: '+972##-###-####',
    IN: '+91(####) ###-###',
    IQ: '+964(###) ###-####',
    IR: '+98(###) ###-####',
    IS: '+354###-####',
    IT: '+39(###) ####-###',
    JM: '+1(876) ###-####',
    JO: '+962#-####-####',
    JP: '+81-##-####-####',
    KE: '+254###-######',
    KG: '+996(###) ###-###',
    KH: '+855##-###-###',
    KM: '+269##-#####',
    KN: '+1(869) ###-####',
    KP: '+850####-#############',
    KR: '+82##-###-####',
    KW: '+965####-####',
    KY: '+1(345) ###-####',
    KZ: '+7(###) ###-##-##',
    LA: '+856##-##-###-###',
    LB: '+961##-###-###',
    LC: '+1(758) ###-####',
    LI: '+423(###) ###-####',
    LK: '+94##-###-####',
    LR: '+231##-###-###',
    LS: '+266#-###-####',
    LT: '+370(###) ##-###',
    LU: '+352(###) ###-###',
    LV: '+371##-###-###',
    LY: '+218##-###-####',
    MA: '+212##-####-###',
    MC: '+377###-###-###',
    MD: '+373####-####',
    ME: '+382##-###-###',
    MF: '+590 ## ## ## ## ##',
    MG: '+261##-##-#####',
    MK: '+389##-###-###',
    ML: '+223##-##-####',
    MM: '+95##-###-###',
    MN: '+976##-##-####',
    MO: '+853####-####',
    MQ: '+596 ## ## ## ## ##',
    MR: '+222##-##-####',
    MS: '+1(664) ###-####',
    MT: '+356####-####',
    MU: '+230####-####',
    MV: '+960###-####',
    MW: '+265#-####-####',
    MX: '+52(###) ###-####',
    MY: '+60(###) ###-###',
    MZ: '+258##-###-###',
    NA: '+264##-###-####',
    NC: '+687 ### ###',
    NE: '+227##-##-####',
    NG: '+234(###) ###-####',
    NI: '+505####-####',
    NL: '+31##-###-####',
    NO: '+47(###) ##-###',
    NP: '+977##-###-###',
    NZ: '+64(###) ###-####',
    OM: '+968##-###-###',
    PA: '+507###-####',
    PE: '+51(###) ###-###',
    PF: '+689 ## ## ## ##',
    PG: '+675(###) ##-###',
    PH: '+63(###) ###-####',
    PK: '+92(###) ###-####',
    PL: '+48(###) ###-###',
    PT: '+351##-###-####',
    PW: '+680###-####',
    PY: '+595(###) ###-###',
    QA: '+974####-####',
    RE: '+262 ## ## ## ## ##',
    RO: '+40##-###-####',
    RS: '+381##-###-####',
    RU: '+7### ###-##-##',
    RW: '+250(###) ###-###',
    SA: '+966#-####-####',
    SB: '+677###-####',
    SC: '+248#-###-###',
    SD: '+249##-###-####',
    SE: '+46##-###-####',
    SG: '+65####-####',
    SH: '+290####',
    SI: '+386##-###-###',
    SK: '+421(###) ###-###',
    SL: '+232##-######',
    SM: '+378####-######',
    SN: '+221##-###-####',
    SO: '+252##-###-###',
    SR: '+597###-####',
    ST: '+239##-#####',
    SV: '+503##-##-####',
    SX: '+1(721) ###-####',
    SY: '+963##-####-###',
    SZ: '+268##-##-####',
    TC: '+1(649) ###-####',
    TD: '+235##-##-##-##',
    TG: '+228##-###-###',
    TH: '+66##-###-####',
    TJ: '+992##-###-####',
    TL: '+670###-#####',
    TM: '+993#-###-####',
    TN: '+216##-###-###',
    TO: '+676#####',
    TR: '+90(###) ###-####',
    TT: '+1(868) ###-####',
    TW: '+886#-####-####',
    TZ: '+255##-###-####',
    UA: '+380(##) ###-##-##',
    UG: '+256(###) ###-###',
    US: '+1(###) ###-####',
    UY: '+598#-###-##-##',
    UZ: '+998##-###-####',
    VC: '+1(784) ###-####',
    VE: '+58(###) ###-####',
    VG: '+1(284)###-####',
    VN: '+84(###) ####-###',
    VU: '+678##-#####',
    WS: '+685##-####',
    XK: '+383##-###-###',
    YE: '+967###-###-###',
    YT: '+262 ## ## ## ## ##',
    ZA: '+27##-###-####',
    ZM: '+260##-###-####',
    ZW: '+263#-######',
    VA: '+39 #### ###-####',
    WF: '+681 ## ##-##',
    VI: '+1(340) ###-####',
    JE: '+44(####) ######',
    AC: '+247###-####',
    TV: '+686###-####',
    TK: '+690###-####',
    TA: '+290###-####',
    SS: '+211### ###-###',
    SJ: '+47 ## ##-##-##',
    PS: '+970 #### ###-###',
    PR: '+1(###) ###-####',
    PM: '+508 ### ##-##-##',
    NU: '+683 ###-####',
    NR: '+674 ###-####',
    NF: '+672##-####',
    MP: '+1(670) ###-####',
    MH: '+692 ###-####',
    KI: '+686 ####-####',
    IO: '+246 ### ####',
    IM: '+44(####) ######',
    GU: '+1(###) ###-###',
    GG: '+44(###) ###-####',
    FO: '+298 ######',
    EH: '+212 #### #####',
    CX: '+61 # ####-####',
    CK: '+682 ## ###',
    CC: '+61 # ####-####',
    AX: '+358 ## ###-####',
    AS: '+1(###) ###-####',
});

/**
 * @deprecated: drop in v5.0
 * A component that can be focused
 */
const TUI_FOCUSABLE_ITEM_ACCESSOR = tuiCreateToken();
/**
 * @deprecated: drop in v5.0
 */
function tuiAsFocusableItemAccessor(accessor) {
    return tuiProvide(TUI_FOCUSABLE_ITEM_ACCESSOR, accessor);
}

/**
 * @deprecated: drop in v5.0
 */
const TUI_FONTS_READY = tuiCreateTokenFromFactory(() => from(inject(DOCUMENT).fonts?.ready || EMPTY).pipe(shareReplay({ bufferSize: 1, refCount: false })));

/**
 * @deprecated: drop in v5.0
 * A key/value dictionary of icon names and src to be defined with TuiSvgService
 */
const TUI_ICON_STARTS = tuiCreateToken({});

/**
 * @deprecated: drop in v5.0
 * Apple(safari/webkit) detection
 */
const TUI_IS_APPLE = tuiCreateTokenFromFactory(() => isApple(inject(WA_NAVIGATOR)));

/**
 * @deprecated: drop in v5.0
 * Chromium browser engine detection
 */
const TUI_IS_CHROMIUM = tuiCreateTokenFromFactory(() => !!inject(WA_WINDOW).chrome);

/**
 * @deprecated: drop in v5.0
 * Firefox browser engine detection
 */
const TUI_IS_FIREFOX = tuiCreateTokenFromFactory(() => inject(WA_WINDOW)
    ?.mozCancelFullScreen !== undefined);

/**
 * @deprecated: drop in v5.0
 */
const TUI_IS_STACKBLITZ = tuiCreateTokenFromFactory(() => inject(WA_WINDOW).location.host.endsWith('stackblitz.io'));

/**
 * @deprecated: drop in v5.0
 * A function to get localized formatted month
 */
const TUI_MONTH_FORMATTER = tuiCreateToken();
const TUI_MONTH_FORMATTER_PROVIDER = {
    provide: TUI_MONTH_FORMATTER,
    deps: [TuiMonthPipe],
    useFactory: (pipe) => (month) => month
        ? pipe
            .transform(month)
            .pipe(map((formatted) => `${formatted} ${month.formattedYear}`))
        : of(''),
};

/**
 * @deprecated: drop in v5.0
 * A custom Sanitizer to sanitize source before inlining
 */
const TUI_SANITIZER = tuiCreateToken();

/**
 * @deprecated: drop in v5.0
 * Appearance for inputs
 * use {@link TUI_TEXTFIELD_APPEARANCE_DIRECTIVE} instead
 */
const TUI_TEXTFIELD_APPEARANCE = tuiCreateToken('textfield');

/**
 * @deprecated: drop in v5.0
 * An interface to communicate with textfield based controls
 */
const TUI_TEXTFIELD_HOST = tuiCreateToken();
/**
 * @deprecated: drop in v5.0
 */
function tuiAsTextfieldHost(host) {
    return tuiProvide(TUI_TEXTFIELD_HOST, host);
}

/**
 * @deprecated: drop in v5.0
 */
const TUI_TOUCH_SUPPORTED = tuiCreateTokenFromFactory(() => inject(WA_WINDOW).matchMedia('(any-pointer: coarse)').matches);

/**
 * @deprecated: drop in v5.0
 * Buffer token to pass NG_VALUE_ACCESSOR to a different Injector
 */
const TUI_VALUE_ACCESSOR = tuiCreateToken();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_COUNTRIES_MASKS, TUI_FOCUSABLE_ITEM_ACCESSOR, TUI_FONTS_READY, TUI_ICON_STARTS, TUI_IS_APPLE, TUI_IS_CHROMIUM, TUI_IS_FIREFOX, TUI_IS_STACKBLITZ, TUI_MONTH_FORMATTER, TUI_MONTH_FORMATTER_PROVIDER, TUI_SANITIZER, TUI_TEXTFIELD_APPEARANCE, TUI_TEXTFIELD_HOST, TUI_TOUCH_SUPPORTED, TUI_VALUE_ACCESSOR, tuiAsFocusableItemAccessor, tuiAsTextfieldHost };

